.dkg-maincatgoryModal-11 {
    .modal-dialog {
        .modal-content {
            .dkg-maincatgoryModalheader-11 {
                color: #fff;
            }

            .dkg-maincatgoryModalBody-11 {
                height: calc(100vh - 240px);

                .dk-toolsModalbody {
                    .tab-content {
                        .tab-pane {
                            height: calc(100vh - 310px);
                            overflow-y: auto;

                            .dk-toolsValuesPanel.dk-toolsReorderPanel {
                                ul {
                                    margin: 0;
                                    padding: 0;
                                }

                                .dkg-reorder-ssavenbtnCon {
                                    margin-top: 1rem;

                                    div {
                                        div {
                                            .dk-toolsPanelSaveBtn {
                                                background-color: #172a38 !important;
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}