
body{
    font-family: 'Poppins', sans-serif;
}
.containerLogin{
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.containerLogin::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.9);
}
.bg{
    background-image: url('./bg-01.jpg');
}
.wrapLogin {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px 55px 37px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3ca7bb+0,2a7685+100 */
    background: rgb(60,167,187); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(60,167,187,1) 0%, rgba(42,118,133,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(60,167,187,1) 0%,rgba(42,118,133,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(60,167,187,1) 0%,rgba(42,118,133,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3ca7bb', endColorstr='#2a7685',GradientType=0 ); /* IE6-9 */

    box-shadow: none !important;
}
.formLogo {
    font-size: 60px;
    color: #333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 auto;
}
.formLogo {
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    box-shadow: none;
}
.loginButtonsTab{
    background: rgb(43, 122, 138);
    border: none !important;
    padding: 8px 15px !important;
    outline: none !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
.loginButtonsTab:hover{
    border: none;
    background: rgb(43, 122, 138);
}
.loginButton{
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #555;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px !important;
    height: 50px;
    border-radius: 25px !important;
    background: #fff !important;
    color: #000 !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.loginButton:hover{
    background: #3d5165 !important;
    color: #fff !important;
}
.forgetPassword, .forgetPassword:hover{
    color: #fff;
    text-decoration: none;
    font-size: 13px;
}
.Login_containerLogin__2yTs_.Login_bg__r5Mqk .Login_wrapLogin__2R_xJ header.MuiPaper-root button span.MuiTab-wrapper {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
}

.MuiButtonGroup-grouped{
    background: rgb(43, 122, 138);
}

.dk-loginBtnTabs button{
    background: rgb(43, 122, 138) !important;
}

.dk-loginBtnTabs .MuiInput-underline:before{
    border-bottom:2px solid rgba(255,255,255,.24);
}
.dklogin-titleColor{
    color: #fff;
}


