.dk-adminSubmenuMain{
    width: 100%;
    position: fixed;
    top: 103px;
    left: 0;
    display: block;
    border: none;
    margin: 0;
    z-index: 9;
    ul{
        list-style: none;
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
        padding: 0;
        background: #eaeaea;
        border-radius: 0 0 5px 5px;
        display: flex;
        li{
            max-width: 9.09%!important;
            display: flex;
            width: 100%;
            border-left: 1px solid #ccc;
            &:first-child{
                border-left: none;
            }
            a{
                color: #333;
                padding:11px 5px;
                width: 100%;
                text-decoration: none;
                font-size: 14px;
                height: 44px;
                &:hover{
                    background: #fff;
                    transition: all 0.5s;
                }
            }
            a.sub-active{
                background: #fff;
            }
        }
    }
}