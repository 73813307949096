.dk-adminLoginMain{
    background: url('../../../assets//images/login_bg.jpg') no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .dk-adminLoginCols{
        width: 100%;
        max-width: 500px;
        margin: auto;
        height: auto;
        background: #fff;
        border-radius:8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid #dcdcdc;
        padding:35px 0 25px;
        .dk-adminLogo{
            text-align: center;
            display: block;
            margin-bottom: 25px;
            img{
                width: 100%;
                max-width: 180px;
            }
        }
        .dk-loginBoxContainer{
            width: 100%;
            max-width:430px;
            margin: auto;
            .nav-tabs{
                flex-wrap: nowrap;
                border: 1px solid #ddd !important;
                margin-bottom: 15px;
                justify-content: space-around;
                padding:7px 10px;
                a{
                    border:none !important;
                    background: transparent;
                    padding:7px 12px;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #333;
                    font-weight:400;
                    font-family: "Roboto",sans-serif;
                }
                a.active{
                    background: #0e76a8;
                    border-radius: 30px;
                    color: #fff;
                }
            }
            .MuiInputBase-root{
                margin-bottom:10px;
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
                .MuiInputAdornment-root{
                    display: none;
                }
                .MuiInputBase-input{
                    border-radius:3px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    font-weight:400;
                    box-shadow: none !important;
                    border:1px solid #ddd !important;
                    padding: 3px 10px;
                }
            }
            .MuiButtonBase-root{
                border-radius: 5px !important;
                display: inline-block;
                background: #2d96b6 !important;
                padding:7px 25px;
                text-transform: uppercase;
                color: #fff !important;
                border: none;
                font-weight: 500;
                text-decoration: none;
                min-height: auto;
                height: auto;
                outline: none;
                &:hover{
                    background: #325f7d !important;
                    transition: all 0.5s;
                }
            }
            a.loginForgot{
                color: #1e70bf;
                text-decoration: none;
            }
            .dk-adminPassText{
                position: relative;
                .dk-passHideShow{
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: #e1e1e4 !important;
                    color:#828282 !important;
                    height: 37px;
                    width: 35px;
                    text-align: center;
                    line-height: 35px;
                    border-radius: 0 3px 3px 0;
                }
            }
        }
    }
}