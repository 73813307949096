.dkg-reorder-valueModal-11 {
    .modal-dialog {
        .modal-content {
            .dkg-reorder-valueModalheader-11 {
                color: #fff;
            }

            .dkg-reorder-valueModalBody-11 {
                height: calc(100vh - 260px);

                .dk-toolsModalbody {
                    .tab-content {
                        height: calc(100vh - 285px);
                        overflow-y: auto;

                        .dk-toolsValuesPanel.dk-toolsReorderPanel {
                            ul {
                                margin: 0;
                                padding: 0;
                            }

                        }
                    }
                }
            }

            .dk-reoorder-value-modalFooter {
                justify-content: center;

                .dkg-reorder-ssavenbtnCon {
                    div {
                        div {
                            .dk-toolsPanelSaveBtn {
                                background-color: #172a38 !important;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}