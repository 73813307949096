.dk-adminMainHeader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: #fff;

    .dk-adminNavbar {
        padding: 6px 15px;

        .dk-nav-title {
            font-size: 18px;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
        }

        .dk-adminLogo {
            width: 100%;
            max-width: 165px;

            a {
                display: inline-block;

                img {
                    height: 45px;
                }
            }
        }

        .dk-nav-profile {
            .dropdown {
                margin: 0;

                .dropdown-toggle {
                    margin: 0 !important;
                    padding: 0;
                    box-shadow: none;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    border: 1px solid #ccc;
                    font-size: 14px;
                    border-radius: 50%;
                }

                .dk-userIcon {
                    display: inline-block;
                    margin: 0 8px;
                }

                .dropdown-img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #fff;
                    border: 1px solid #ccc;
                    padding: 3px;

                    img {
                        border-radius: 50%;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 5px;
                    border: none;
                    margin-top: 7px !important;
                    z-index: 999;

                    a {
                        display: block;
                        padding: 8px 10px;
                        border-radius: 5px;

                        &:hover {
                            background: #f2f2f2;
                        }
                    }
                }
            }
        }
    }
}