.dk-mlAddNewModal{
    .modal-dialog{
        min-width: 40%;
        max-width: 40%;
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        .modal-content{
            border-radius: 5px;
            .modal-header{
                border-radius: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                align-items: center;
                background-color: #26597d;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                position: relative;
                .modal-title{
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight:500;
                    color: #fff;
                }
                .close{
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size:26px;
                    outline: none;
                }
            }
            .dk-mlAddModalBody{
                padding:20px 25px;
                .form-group{
                    display: -webkit-flex;
                    display: flex;
                    margin-bottom: 1rem;
                    -webkit-align-items: center;
                    align-items: center;
                    label{
                        margin:0;
                        font-weight: 400;
                        font-size: 15px;
                        width: 100%;
                        &:first-child{
                            width: 100%;
                            max-width: 150px;
                        }
                        .selection{
                            border:1px solid #ddd !important;
                            border-radius: 0;
                            height: 35px;
                            min-height: 35px;
                            padding: 10px 10px;
                            text-align: center;
                        }
                        .dropdown{
                            i{
                                padding:9px !important;
                            }
                        }
                        .visible{
                            border-color: #ddd !important;
                        }
                    }
                    input{
                        border-radius: 0;
                        border:1px solid #ddd !important;
                        box-shadow:none !important;
                        text-align:center;
                        height: 35px;
                    }
                    select{
                        border-radius: 0;
                        border:1px solid #ddd !important;
                        box-shadow: none !important;
                    }
                    .dk-userList{
                        .dropdown{
                            .dropdown-toggle{
                                -webkit-align-items: center;
                                align-items: center;
                                background-color: #f5f2f2 !important;
                                color: #333;
                                border-radius: 50%;
                                display: -webkit-flex;
                                display: flex;
                                -webkit-flex-shrink: 0;
                                flex-shrink: 0;
                                height: 38px;
                                -webkit-justify-content: center;
                                justify-content: center;
                                width: 38px;
                                padding: 0;
                                box-shadow: none !important;
                                font-size: 16px;
                                border:1px solid #ddd;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                padding: 6px 7px;
                                transform: inherit !important;
                                left:60px !important;
                                right: inherit !important;
                                border-radius: 3px;
                                border: 1px solid #ddd;
                                background: #f2f2f2;
                                top: -5px !important;
                                margin: 0 !important;
                                &::before{
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-color: transparent #ccc transparent transparent;
                                    border-style: solid;
                                    border-width: 8.5px 12px 8.5px 0;
                                    line-height: 0;
                                    _border-color: #000 #ccc #000 #000;
                                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                    position: absolute;
                                    left: -12px;
                                    top: 15px;
                                }
                                a{
                                    padding: 0;
                                    width: 35px;
                                    margin: 0 3px;
                                    img{
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
                .dk-resourcePopupbtnCont{
                    text-align: center;
                    button{
                        background: #26597d!important;
                        border: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding:10px 25px;
                        font-weight: 500;
                        font-size: 14px;
                        outline: none;
                        cursor: pointer;
                        border-radius: 5px;
                        font-weight:600;
                        &:hover{
                            background: #333 !important;
                            transition: all 0.5s;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}

.dk-mlAddNewModal.dk-editResourceModal{
    .modal-dialog{
        .modal-content{
            .modal-header{
                background-color: #fee69c;
                .modal-title{
                    color: #333;
                }
                .close{
                    color: #333;
                    padding: 0;
                    position: absolute;
                    right: 24px;
                    top: 25px;
                }
            }
            .dk-resourcePopupbtnCont{
                button{
                    background: #fee69c !important;
                    color: #333 !important;
                }
            }
        }
    }
}

.dk-mlAddNewModal.dk-deleteModal .modal-dialog {
    min-width: 30%;
    max-width: 30%;
    .modal-body{
        p{
            font-size: 16px;
            color:#e2445b;
            font-weight: 400;
            padding: 15px 0;
            display: block;
        }
        .d-flex{
            button{
                border: 1px solid #333;
                color: #333;
                background:transparent;
                display: inline-block;
                width: 100%;
                max-width: 90px;
                border-radius: 5px;
                padding: 7px 0;
                transition: all 0.5s;
                outline: none;
                &:hover{
                    background: #535353;
                    border-color: #535353;
                    color: #fff;
                }
            }
            button.dk-yesBtn{
                background: #e2445b;
                border: 1px solid #e2445b;
                color: #fff;
                display: inline-block;
                width: 100%;
                max-width: 90px;
                min-width: 90px;
                border-radius: 5px;
                padding: 7px 0;
                margin-left: 10px;
                &:hover{
                    background: #535353;
                    border-color: #535353;
                }
            }
        }
    }
}

.dk-mlAddNewModal.dk-viewResourceModal{
    .modal-dialog{
        .modal-content{
            .modal-header{
                background-color: #2196f3;
            }
        }
    }
}