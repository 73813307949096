.dk-colorPickerModal{
    color: #2e536f;
    .modal-dialog{
        .modal-content{
            .modal-header{
                .close{
                    &:hover{
                        outline: 0;
                        box-shadow: none;
                    }
                    &:focus{
                        outline: 0;
                    }
                }
            }
            .modal-footer{
                color: #333;
                .dk-color-close-btn{
                    background: transparent !important;
                    color: #e2445b;
                    display: inline-block;
                    border: none;
                    outline: none;
                    border-radius: 3px;
                    width: 100%;
                    max-width: 90px;
                    min-width: 90px;
                    padding: 10px 0;
                    text-align: center;
                    margin: 0 5px;
                    transition: all 0.5s;
                    box-shadow: none !important;
                    font-size: 14px;
                    &:hover{
                        background: #e2445b !important;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
                button.dk-color-ok-btn {
                    display: inline-block;
                    background: #2e536f !important;
                    border: none;
                    outline: none;
                    border-radius: 3px;
                    width: 100%;
                    max-width: 90px;
                    min-width: 90px;
                    color: #fff;
                    padding: 5px 0;
                    text-align: center;
                    margin: 0 5px;
                    transition: all 0.5s;
                    box-shadow: none !important;
                    font-size: 14px;
                }
            }
        }
    }
}